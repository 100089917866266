import $ from 'jquery';
import ScrollManager from './scroll/vendors/ScrollManager';

export default class SmoothScroll {

	constructor() {
		// Init Locomotive Scroll
	    this.scroller = new ScrollManager({
	        container: $('#js-scroll'),
	        selector: '.js-animate',
	        smooth: true,
	        smoothMobile: false,
	        mobileContainer: $(document),
	        getWay: false,
	        getSpeed: false
	    });

	}

}
