import $ from 'jquery';

export default class ProductNav {
	
	constructor() {
		const $productbtn = $('.js-showproducts');
		this.productbtn = $productbtn;
		this.btn = $('.js-togglenav');
		this.productbtn.on('click', this.openProducts.bind(this));
	}	

	openProducts(e) {
		e.preventDefault();
		$('html').addClass('showing-product-nav');
		this.btn.addClass('is-active js-productsopen');
		// Listen for escape key press
		$(document).on('keyup.closeproducts', (e) => {
  			if (e.keyCode == 27) { // escape key maps to keycode `27`
        		this.closeProducts();
    		}
		});
	}
	closeProducts() {
		$('html').removeClass('showing-product-nav');
		this.btn.removeClass('is-active js-productsopen');
		$(document).off('keyup.closeproducts');
	}

}