import $ from 'jquery';
import throttle from 'lodash/throttle';

export default class PlayVideoOnEnter {

	constructor(_el, smoothScroller) {

		this.video = _el;

		$('.maincontent').on('is-scrolling', (event, scrollY) => {
			this.onScroll(scrollY);
		});
		this.onScroll(0);
		
	}

	onScroll(scrollY) {
		// console.log(scrollY);
		// Check if the element is visible within the viewport
		let rect = this.video.getBoundingClientRect();
		let windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

	    if (rect.top > windowHeight || rect.bottom < 0) {
	    	// Entirely out of the viewport - STOP VIDEO
	    	this.stopVideo();

	    } else if (!this.isPlaying) {

	    	// Partially visible! - PLAY VIDEO
	    	this.playVideo();
	    }
	}

	stopVideo() {
		if (!this.video.paused) {
			this.video.pause();
			this.video.currentTime = 0;
			this.isPlaying = false;	
		}
	}

	playVideo() {
		let self = this;

		let playPromise = this.video.play();

		if (playPromise !== undefined) {

			// Browser supports promises!
			playPromise.then(function() {

				// Automatic playback started!
				self.isPlaying = true;
				self.video.removeAttribute('controls');

			}).catch(function(error) {

				// Automatic playback failed. Pass control to the user...
				self.video.setAttribute('controls', 'controls');
			});

		} else {

			// Browser does not support promises :(
			// lets check if video position has changed anyway? (MSEdge)
			let t = self.video.currentTime;
			setTimeout(function(){
				if (t < self.video.currentTime) {

					// No promises, but IS playing!
					self.isPlaying = true;
					self.video.removeAttribute('controls');

				} else {

					// No prmoises, no playback. Pass control to the user...
					self.video.setAttribute('controls', 'controls');
				}
			}, 200);
		}
	}
}