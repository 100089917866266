import $ from "jquery";
import "slick-carousel";
import SmoothScroll from "../src/js/abs-smoothscroll";
import ProductNav from "../src/js/abs-productnav";
import PlayVideoOnEnter from "../src/js/play-video-on-enter"

window.onload = (event) => {
  console.log('onload');
  var currentSlide = 0;
  var el = $(".js-product-slider");
  var slicker = el.find(".productslider__slider");
  var customNavBtns = el.find(".productslider__btns");

  let smoothScroller;

  setTimeout(() => {
    console.log('init smooth scroll');
    smoothScroller = new SmoothScroll();
  }, 100);

  // If we are not on the contact page then initialise the smooth scroll.
  if (!$(".contactpage").length) {

  } else {
    // If we are on a contact page trigger the event that we are so the listener in the
    // ScrollManager picks up on it and destroys any existing scroll.
    $(document).trigger("intlFormOnPage");
  }

  $(".page-template-template-anti-ghost-product .product-header a").on(
    "click",
    function (e) {
      smoothScroller.scroller.scrollToSection(
        this,
        $($(this).attr("href")).get(0)
      );
    }
  );

  new ProductNav();

  $(".scroll-to-products").on("click", function () {
    smoothScroller.scroller.scrollToSection(
      this,
      $($(this).attr("href")).get(0)
    );
  });

  $(".smooth-scroll a").on("click", function () {
    smoothScroller.scroller.scrollToSection(
      this,
      $($(this).attr("href")).get(0)
    );
  });

  $(".js-play-video-on-enter").each((index, element) => {
    new PlayVideoOnEnter(element, smoothScroller);
  });

  $(".letterbox-video__playbtn").on("click", function () {
    smoothScroller.scroller.scrollToSection(
      this,
      $("#letterboxvideo").get(0)
    );
  });


  slicker.slick({
    dots: true,
    arrows: false,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });

  slicker.on("beforeChange", (event, slick, currentSlide, nextSlide) => {
    activateBtn(nextSlide);
  });

  customNavBtns.find("button").on("click", goToSlide);

  function activateBtn(nextSlide) {
    // First deactivate buttons
    customNavBtns.find("button").removeClass("is-active");
    // Now activate the current number
    customNavBtns.find("button").eq(nextSlide).addClass("is-active");
  }

  function goToSlide(e) {
    e.preventDefault();
    let $btn = $(e.target);
    let slide = $btn.data("slideto");
    slicker.slick("slickGoTo", slide);
  }
};
