// ==========================================================================
// Locomotive Scroll Manager
// ==========================================================================
/* jshint esnext: true */
import $ from 'jquery';
import { $document, $window, $html, $body } from '../../utils/environment';
import Scroll, { DEFAULTS, EVENT } from '../Scroll';
import SmoothScroll from '../SmoothScroll';

/**
 * Basic module that detects which scrolling module we'll be using
 */
export default class {
    constructor(options) {
        this.options = options;
        this.smooth = options.smooth || DEFAULTS.smooth;
        this.smoothMobile = options.smoothMobile || DEFAULTS.smoothMobile;
        this.mobileContainer = options.mobileContainer || DEFAULTS.mobileContainer;
        this.isMobile = false;
        // Removed to be triggered from the init.
        this.init();

        // Add a listener for if the form is on the page. If it is then destroy the scroll.
        $document.on('intlFormOnPage', () => {
            this.destroy();
        });

    }

    init() {
        $html[0].scrollTop = 0;
        $body[0].scrollTop = 0;

        if (!this.smoothMobile) {
            this.isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        }

        this.instance = (() => {
            if (this.smooth === true && !this.isMobile) {
                return new SmoothScroll(this.options);
            } else {
                if (this.mobileContainer) {
                    this.options.container = this.mobileContainer
                }
                return new Scroll(this.options);
            }
        })();

        this.instance.init();

        const $scrollToOnLoadEl = $('.js-scrollto-on-load').first();

        if ($scrollToOnLoadEl.length === 1) {
            $document.triggerHandler({
                type: 'Event.SCROLLTO',
                options: {
                    targetElem: $scrollToOnLoadEl
                }
            });
        }

    }

    // Rob added
    scrollToTopOfPage() {
        if (this.smooth === true && !this.isMobile) {
            this.instance.scrollTo({speed: 1}, 0, 0, 1);
        } else {
            // Scroll top of page standard
            $('html', 'body').scrollTop(0);
        }
    }

	scrollToSection(srcElement, element) {
		this.instance.scrollTo({
			sourceElem: $(srcElement),
			targetElem: jQuery(element),
			speed: 2000,
		});
	}

    destroy() {
        // Remove is form on page listener.
        $document.off('intlFormOnPage');
        this.instance.destroy();
    }
}
